<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-6">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">{{ lista_campos != '' ? 'Editar' : 'Criar' }} filial</h3>
        </div>
        <div class="card-body">
          <div  class="form">
            <div class="form-group row">
              <div class="col-md-8">
                <label class="col-md-12">Nome:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.nome"
                    placeholder="Digite o nome..."
                />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">Sigla:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.sigla"
                    placeholder="Digite a Sigla..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.descricao"
                    placeholder="Digite a Descricao..."
                />
              </div>

              <div class="col-md-12 mt-3">
                <label class="col-md-12">Empresas:*</label>
                <select
                    class="form-control"
                    v-model="form.empresa_id"
                    id=""
                >
                  <option
                      v-for="(lista, index) in lista_empresas"
                      :key="index"
                      :value="lista.id"
                  >
                    {{ lista.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                    @click="confirm(lista_campos == '' ? 'cria' : 'edita')"
                    class="btn btn-primary"
                    :disabled="verif"
                >
                  Salvar
                  <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {fireAlert} from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        sigla: "",
        descricao: "",
        empresa_id: "",
        status: 1,
      },
      verif: false,
    };
  },
  computed: {
    lista_empresas() {
      return this.$store.state.configEmpresa.lista_empresas;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert
    }
  },

  created() {
    this.$store.dispatch("configEmpresa/listar_empresas");
    this.preenxerCampos();
  },
  methods: {
    async confirm(tipo) {
      console.log('a')
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` una filial no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("configEmpresa/create_filial", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'filial'
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("configEmpresa/update_filial", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'filial'
      });
    },
    preenxerCampos() {
      if (this.lista_campos != '')
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          sigla: this.lista_campos.sigla,

          descricao: this.lista_campos.descricao,
          status: this.lista_campos.status,
          empresa_id: this.lista_campos.empresa_id,
        };
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>